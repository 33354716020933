import { Modal } from 'bootstrap'
import $ from 'jquery'
import 'jQuery-QueryBuilder'
import { headers, stringifyData } from '../utils/helpers/apiHelpers'
import { gettext } from '../utils/translation'
import { getFromSession, setToSession } from './localStorage'
import {
	hide,
	show,
	setElementVisibility,
	setVisibility,
} from '../utils/helpers/show'
import { successMessage } from '../globals/messages'
import { escapeHtml } from './html'
import { alertDanger } from '../globals/alerts'
import {
	formatDateForAPI,
	formatDateFromAPI,
	checkIfAPIDate,
} from './helpers/dateFormatter'
import { getContextData } from '../utils/django'

/**
 * Retrieves all custom filters and shows them in `.customFilterDropdown`
 *
 * @param {string} filterModel - name of the filter type, eg. userFilter. Depends on the page you call this
 * @param {Function?} callback - callback function executed after the filter has been saved
 */
export async function getAndShowCustomFilters(filterModel, callback) {
	await getCustomFilters(filterModel)
	showCustomFilters(callback)
	setEventListeners()
}
/**
 * Retrieve custom filters an stores them under the key `customFilters` in sessionStorage
 *
 * @param {string} filterModel - name of the filter type, eg. userFilter. Depends on the page you call this
 * @returns {Promise<any>} returns the promise so you can await the result.
 */
export async function getCustomFilters(filterModel) {
	// TODO: See #9608
	const url = `/app/api/requestmodel/CustomFilter/?filter=${JSON.stringify({
		model: filterModel,
	})}&limit=9999`
	return await fetch(url)
		.then((response) => response.json())
		.then((data) => {
			if (!data.success) {
				alertDanger(undefined, `${data.code}: ${data.msg}`, {
					shortDescriptionOnly: true,
				})
			}
			setToSession('customFilters', data.data)
		})
}

/**
 * Shows custom filters from session storage in `.customFilterDropdown` or displays a message if none are stored.
 *
 * @param {Function?} callback - callback function executed after the filter has been saved
 */
export function showCustomFilters(callback) {
	document
		.querySelectorAll('.customFilterLink')
		.forEach((element) => element.remove())
	const customFilters = getFromSession('customFilters') || []
	const dropdown = document.querySelector('.customFilterDropdown')
	customFilters.forEach((filter, i) => {
		const name = escapeHtml(filter.name)
		let html = `<li class="customFilterLink d-flex" data-number="${i}">`
		html += `<a class="dropdown-item applyCustomFilterLink cursor-pointer" role="button">${name}</a>`
		html +=
			'<a class="dropdown-item w-auto editCustomFiltersLink cursor-pointer" role="button"><i class="far fa-edit fa-w-18 fa-fw"></i></a>'
		html += '</li>'
		dropdown.insertAdjacentHTML('beforeend', html)
	})
	document.querySelectorAll('.customFilterLink').forEach((element) => {
		const number = element.dataset.number
		element
			.querySelector('.applyCustomFilterLink')
			.addEventListener('click', () =>
				applyCustomFilter(number, false, false, callback)
			)
		element
			.querySelector('.editCustomFiltersLink')
			.addEventListener('click', () => editCustomFilters(number))
	})

	if (customFilters.length === 0) {
		const html = `<li class="customFilterLink"><span class="dropdown-item cursor-pointer">${gettext(
			'Keine eigenen Filter gespeichert'
		)}</span></li>`
		dropdown.insertAdjacentHTML('beforeend', html)
	}
	// TODO: master in master-redesign: `master` now updates the chosen element `.rule-value-container select` here
}

/**
 * Saves a filter as session filter
 *
 * @param {string} filterType - name of the filter type, eg. userFilter. Depends on the page you call this
 * @param {boolean} isEmpty - if true sets rules explicitly to {}
 * @param {Function?} callback - callback function executed after the filter has been saved
 * @param {string} customFilterMessage - (optional) if we need a different text in filterNotice
 * @param {boolean} saveCustomFilterAdditionally - (optional) also save the custom filter
 */
export async function saveFilter(
	filterType,
	isEmpty,
	callback,
	customFilterMessage = undefined,
	saveCustomFilterAdditionally = false
) {
	if (
		$('#builder').queryBuilder('validate') ||
		isEmpty === true ||
		catchSafariFilterBug()
	) {
		let rules = $('#builder').queryBuilder('getRules')

		const start = document.querySelector('#filters input[name=start]')
		const end = document.querySelector('#filters input[name=end]')

		if (rules?.rules?.length > 0) {
			for (const rule of rules.rules) {
				if (rule.type === 'date') {
					if (rule.operator === 'greater_or_equal' && start) {
						start.value = rule.value
					} else if (rule.operator === 'less_or_equal' && end) {
						end.value = rule.value
					}
					if (rule.value && rule.value.includes('.')) {
						// The date format must be YYYY-MM-DD if it is not already
						rule.value = formatDateForAPI(rule.value)
					}
				}
			}
		}

		if (isEmpty) {
			rules = {}
			$('#builder').queryBuilder('reset')
		}

		if (rules.rules) rules.rules = changeValueFormat(rules.rules, false)

		const data = stringifyData({
			filterType: filterType,
			filterModel: JSON.stringify(rules),
		})
		if (data === null) {
			return
		}
		const url = '/app/api/filter/'
		const tabSelector = getTabSelector()
		const response = await fetch(url, { method: 'POST', body: data })

		const resetButton = document.querySelector('#resetFilterButton')
		resetButton.disabled = isEmpty

		showFilterActivationNotice(
			undefined,
			tabSelector,
			rules,
			customFilterMessage
		)

		if (response.ok) {
			if (
				saveCustomFilterAdditionally &&
				document.querySelector('#filter_name').value
			) {
				await saveCustomFilter(filterType, false, false, callback)
			} else if (callback) {
				callback(response)
			}
		}

		closeModal()
	} else {
		alertDanger('VLx400xEmptyFilter', undefined, {
			shortDescriptionOnly: true,
		})
	}
}

/**
 * Fixes a bug in Safari where the filter is not applied correctly by skipping the validation
 *
 * @returns {boolean} - true if the browser is Safari and the specific Filter is applied
 */
function catchSafariFilterBug() {
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
	const hasAddressSuggestion =
		document.querySelectorAll('.addressSuggestion').length > 0

	if (isSafari && hasAddressSuggestion) {
		document.querySelectorAll('.addressSuggestion').forEach((element) => {
			$(element).typeahead('val', '')
			element.dispatchEvent(new Event('change'))
		})
		return true
	}
	return false
}

/**
 * Get the selector of the tab containing the .filterNotice (if there is a tab)
 *
 * @returns {string} - The selector of the current tab or ''
 */
function getTabSelector() {
	const tabId = document.querySelector('.tab-pane .active')?.id
	return tabId ? `#${tabId}` : ''
}

/**
 * Saves a new or updates an existing custom filter
 *
 * Takes `filterName` from `#filter_name`'s value and `filterId` from `#filter`'s dataset.id.
 *
 * @param {string} filterType - name of the filter type, eg. userFilter. Depends on the page you call this
 * @param {boolean} isEmpty - if true skip queryBuilder validation and save an empty filter
 * @param {boolean} remove - if true, delete the filter
 * @param {Function?} callback - callback function executed after the filter has been saved
 */
export async function saveCustomFilter(filterType, isEmpty, remove, callback) {
	const filterName = document.querySelector('#filter_name').value
	const filterId = document.querySelector('#filter').dataset.id

	if (!filterName || filterName === '') {
		await alertDanger('VLx400xMissingRequiredField', undefined, {
			shortDescriptionOnly: true,
			messageData: gettext('Name für eigenen Filter'),
		})
		return
	}

	const queryBuilder = $('#builder')[0].queryBuilder

	if (queryBuilder.validate() || isEmpty === true) {
		const rules = queryBuilder.getRules()
		for (const rule of rules.rules) {
			if (rule.type === 'date' && rule.value && rule.value.includes('.')) {
				// The date format must be YYYY-MM-DD if it is not already
				rule.value = formatDateForAPI(rule.value)
			}
		}
		const data = {
			filterName: filterName,
			filterType: filterType,
			filterModel: JSON.stringify(rules),
			filterId: filterId,
			update: filterId !== undefined && !remove,
			delete: remove,
		}

		const url = '/app/api/updatecustomfilter/'
		fetch(url, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: headers(),
		})
			.then((response) => response.json())
			.then((data) => {
				successMessage(data)
				getAndShowCustomFilters(filterType, callback)
				closeModal()
			})
	} else {
		alertDanger('VLx400xEmptyFilter', undefined, {
			shortDescriptionOnly: true,
		})
	}
}

/**
 * Try to set the rules in the query builder and remove rules that cause errors
 *
 * @param {Array} rules - filter rules to set and clean up
 */
export async function setRulesAndCleanUpRules(rules) {
	try {
		$('#builder').queryBuilder('setRules', rules)
	} catch (error) {
		const ex = error.toString()

		// removing of deleted customField-rules or sphere (if SKR 42 is not enabled) in filters
		if (
			ex.includes('UndefinedFilterError') &&
			(ex.includes('customfield') || ex.includes('"sphere"'))
		) {
			const firstIdx = ex.indexOf('"') + 1
			const lastIdx = ex.indexOf('"', firstIdx)

			const undefinedField = ex.substring(firstIdx, lastIdx)

			if (undefinedField) {
				while (removeDeprecatedFilter(rules.rules, undefinedField)) {
					console.warn(
						`The rule "${undefinedField}" was removed from the filter rules because it is deprecated!`
					)
				}

				if (rules.rules.length > 0) {
					setRulesAndCleanUpRules(rules)
				}
			}
		}
	}
}

/**
 * Applies a selected filter to filter the current datatable
 * OR just opens the modal for changing a already saved custom filter depending on the given edit attribute
 *
 * @param {number} index - index of the custom filter stored under `"customFilters"` in session storage
 * @param {boolean} save - if true also save the selected filter as the session filter in the backend
 * @param {boolean} edit - bool that determines, if we are editing a customfilter
 * @param {Function?} callback - callback function executed after the filter has been saved
 */
export async function applyCustomFilter(index, save, edit = false, callback) {
	const customFilters = await getFromSession('customFilters')
	const customFilter = customFilters[index]
	const customFilterName = customFilter.name
	const rules = JSON.parse(customFilter.rules)

	if (rules.rules) rules.rules = changeValueFormat(rules.rules, true)

	await setRulesAndCleanUpRules(rules)

	if (!edit && callback && rules.rules.length > 0) {
		await saveFilter(
			document.querySelector('#filter').dataset.filterModel,
			false,
			callback
		)
	}

	if (save) {
		await saveFilter(customFilter)
	}

	if (!edit) {
		showFilterActivationNotice(customFilterName, customFilter)
	}
	setToSession('filterName', customFilterName)
	closeModal()
}

/**
 * Changes the value format of certain field in the filter rules
 *
 * @param {Array} rules - An array of the rules to change the value for
 * @param {boolean} [fromApi=true] - A boolean indicating if the format is from the api or for the api
 * @returns {Array} An array of the rules with changed values
 */
export function changeValueFormat(rules, fromApi = true) {
	if (!rules) return rules // needed for resetting filter rules #12333

	for (const rule of rules) {
		if (rule.condition) {
			const subRules = rule.rules
			changeValueFormat(subRules, fromApi)
		} else {
			// fix for boolean filters cause the setRules function is expecting 1/0 instead of true/false
			if (rule.type === 'boolean') {
				if (typeof rule.value === 'boolean') rule.value = rule.value ? 1 : 0
				else if (typeof rule.value === 'string')
					rule.value = parseInt(rule.value)
			} else if (rule.type === 'date' && rule.value) {
				if (fromApi) {
					rule.value = rule.value.includes('.')
						? rule.value
						: formatDateFromAPI(rule.value)
				} else {
					rule.value = rule.value.includes('-')
						? rule.value
						: formatDateForAPI(rule.value)
				}
			}
			// This needs to be treated differently when a filter that was set in Master is used in Hexa,
			// otherwise the wrong (opposite) value will be set in the filter mask
			// Old filters should probably be rewritten at some point to get rid of this case
			else if (rule.id === 'hasOpenInvoices') {
				rule.value = rule.value === '0' ? 1 : 0
			}
			// Rewrite this filter, so that it does the same as the billingAccount isnull filter, so that it does what it should
			else if (
				rule.id === 'billingAccount' &&
				rule.operator === 'equal' &&
				rule.value === -1
			) {
				rule.operator = 'is_null'
				rule.value = true
			}
		}
	}
	return rules
}
/**
 * Opens the `#filter` modal and loads and applies the rules of the filter that is stored in `customFilters[index]`
 *
 * @param {number} index - index of the custom filter stored under `"customFilters"` in session storage
 */
export async function editCustomFilters(index) {
	const customFilters = getFromSession('customFilters')
	const filter = customFilters[index]
	await applyCustomFilter(index, false, true)
	document.querySelector('#filter_name').value = filter.name
	document.querySelector('#saveCustomFilter').disabled = false
	document.querySelector('#filter').dataset.id = filter.pk
	show('#deleteCustomFilter')
	const openedModal = document.querySelector('.modal.show')
	if (openedModal) {
		Modal.getOrCreateInstance(openedModal)?.hide()
	}
	showModal()
}

/**
 * Recursivly removes rules from ruleSet
 *
 * @param {Array<object>} ruleSet - list of rules
 * @param {string} fieldName - field id (eg. contactDetails__isCompany)
 * @returns {boolean} true if a rule has been removed
 */
function removeDeprecatedFilter(ruleSet, fieldName) {
	for (const rule in ruleSet) {
		if (
			ruleSet[rule].id === fieldName ||
			(ruleSet[rule].rules !== undefined && ruleSet[rule].rules.length === 0)
		) {
			ruleSet.splice(rule, 1)
			return true
		} else if (ruleSet[rule].rules !== undefined) {
			if (removeDeprecatedFilter(ruleSet[rule].rules, fieldName)) {
				return true
			}
		}
	}
	return false
}

/**
 * Displays an alert in `.filterNotice`, that a filter is active.
 * If `filterName` is given, it displays the active filters name, too.
 *
 * @param {string} filterName - display name of the filter
 * @param {object} filters - the filter for the current table
 */
export function showFilterActivationNotice(filterName, filters = undefined) {
	if (!filters) filters = $('#builder').queryBuilder('getRules')

	showOrHideFilterBadge(escapeHtml(filterName), filters)
}

/**
 * Helper that shows the filter modal
 */
function showModal() {
	Modal.getOrCreateInstance(document.querySelector('#filter')).show()
}

/**
 * Helper that closes the filter modal
 */
function closeModal() {
	Modal.getOrCreateInstance(document.querySelector('#filter')).hide()
	const filterConditionsInput = document.querySelectorAll('#filter input')
	filterConditionsInput[0].value = 'AND'
	filterConditionsInput[1].value = 'OR'
}

/**
 * Reset all selects and inputs of the filter modal
 */
export function resetFilterModal() {
	document.querySelector('#filter_name').value = ''
	document.querySelector('#saveCustomFilter').disabled = true
	hide('#deleteCustomFilter')

	if (isFilterRulesSet(true)) {
		return // if filters are set, dont remove rules
	}
	const filter = document.querySelector('#filter')
	filter.querySelectorAll('select').forEach((select) => {
		select.value = -1
	})
	filter.querySelectorAll('input').forEach((input) => {
		input.value = ''
	})
}

/**
 * checks if filter rules are empty
 *
 * @param {boolean} allowInvalid - if true, invalid rules are allowed
 *
 * @returns {boolean} true if a rule has been set
 */
export function isFilterRulesSet(allowInvalid = false) {
	const rules = $('#builder').queryBuilder('getRules', {
		allow_invalid: allowInvalid,
	})
	if (!rules || (rules && rules.rules.length === 0)) {
		return false
	}
	return true
}

/**
 * Sets event listeners for the filter
 */
function setEventListeners() {
	const filter = document.querySelector('#filter')
	filter.addEventListener('hide.bs.modal', () => {
		filter.removeAttribute('data-id')
	})
}

/**
 * Show the filter badge for the active filter
 *
 * @param {string} filterName - the name of the filter (if it is known)
 * @param {object} filters - the filter for this table
 */
export function showOrHideFilterBadge(
	filterName = undefined,
	filters = undefined
) {
	let start = ''
	let end = ''
	let timeFilterActive = false
	let otherFiltersActive = false
	if (filters && filters.rules) {
		for (const rule of filters.rules) {
			if (rule.id === 'date') {
				if (
					rule.operator !== 'greater_or_equal' &&
					rule.operator !== 'less_or_equal'
				) {
					start = ''
					end = ''
					break
				} else if (rule.operator === 'greater_or_equal') {
					start = rule.value
				} else if (rule.operator === 'less_or_equal') {
					end = rule.value
				}
				timeFilterActive = true
			} else {
				otherFiltersActive = true
			}
		}
		if (!start || !end) {
			start = ''
			end = ''
		}
	}

	let formattedStart = ''
	let formattedEnd = ''
	if (timeFilterActive) {
		const dateRanges = getContextData('dateranges')
		if (dateRanges) {
			const allTimeRange = dateRanges.allTime
			formattedStart = checkIfAPIDate(start) ? formatDateFromAPI(start) : start
			formattedEnd = checkIfAPIDate(end) ? formatDateFromAPI(end) : end
			if (
				formattedStart === allTimeRange[0] &&
				formattedEnd === allTimeRange[1]
			) {
				timeFilterActive = false
			}
		}
	}

	const timeFilterBadges = document.querySelectorAll(
		'.activeFilter .timeFilterBadge'
	)
	let timeframeString = ''
	if (timeFilterActive) {
		if (start && end) timeframeString = `${formattedStart} - ${formattedEnd}`
		else timeframeString = `${gettext('Zeitfilter aktiv')}`
	}
	timeFilterBadges.forEach((el) => {
		el.querySelector('.filterName').innerText = timeframeString
		setElementVisibility(el, timeFilterActive)
	})

	const filterActiveBadges = document.querySelectorAll(
		'.activeFilter .filterActiveBadge'
	)
	if (otherFiltersActive) {
		if (filterName === undefined) filterName = gettext('Filter aktiv')
		filterActiveBadges.forEach((el) => {
			el.querySelector('.filterName').innerText = filterName
		})
	}
	setVisibility('.activeFilter .filterActiveBadge', otherFiltersActive)
}

/**
 * Prepare the reset button for the filter badge
 *
 * @param {Function} callback - the function to call after the filter got saved
 */
export function setupFilterBadge(callback = undefined) {
	if (!callback)
		callback = () => {
			window.location.reload()
		}
	const activeFilterBadgesReset = document.querySelectorAll(
		'.filterActiveBadge .resetFilterBadge'
	)
	activeFilterBadgesReset.forEach((activeFilterBadgeReset) => {
		activeFilterBadgeReset.addEventListener('click', () => {
			const filter = document.querySelector('#filter')
			if (!filter) return
			const filterModel = document.querySelector('#filter').dataset.filterModel
			if (!filterModel) return
			saveFilter(filterModel, true, callback)
		})
	})
	if (document.querySelector('#filterCompleteTime')) {
		const timeFilterBadges = document.querySelectorAll(
			'.timeFilterBadge .resetFilterBadge'
		)
		timeFilterBadges.forEach((el) => {
			el.addEventListener('click', () => {
				document
					.querySelector('#filterCompleteTime')
					.dispatchEvent(new Event('click'))
			})
		})
	}
}
